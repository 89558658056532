<template>
  <RaiSetups v-model="activeSetup">
    <StoreSetup
      :store="store"
      :time-zones="timeZones"
      :loading="loading"
      :show-new-store="showNewStore"
      @update:store="onUpdateStore"
      @new:store="onNewStore"
    />
    <RouterView />
  </RaiSetups>
</template>

<script>
import { RaiSetups } from "@/core-ui";
import { hasSnackbarAccess } from "@/mixins/snackbar";
import { STORE_QUERY, STORE_UPDATE, TIME_ZONES_QUERY } from "./graphql";
import StoreSetup from "./components/StoreSetup.vue";

export default {
  name: "StoreView",
  components: {
    RaiSetups,
    StoreSetup,
  },
  mixins: [hasSnackbarAccess],
  props: {
    storeId: {
      type: [String, Number],
      required: true,
    },
    showNewStore: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    activeSetup: 0,
    loading: false,
    timeZones: [],
  }),
  apollo: {
    store: {
      query: STORE_QUERY,
      watchLoading(loading) {
        this.loading = loading;
      },
      variables() {
        return { id: this.storeId };
      },
      skip() {
        return !this.storeId;
      },
    },
    timeZones: {
      query: TIME_ZONES_QUERY,
      update: (data) => data.timeZones.map((x) => x.name),
      watchLoading(loading) {
        this.loading = loading;
      },
    },
  },
  methods: {
    onNewStore() {
      this.$router.push({ name: "settings.store.new" });
    },
    async onUpdateStore({ values, resolve, setErrors }) {
      const response = await this.$apollo.mutate({
        mutation: STORE_UPDATE,
        variables: {
          input: { ...values, id: this.storeId },
        },
      });

      const data = response.data.storeUpdate;

      if (data.errors?.length > 0) {
        setErrors(data.errors);
        return;
      }

      this.store = data.store;
      this.showSnackbar({
        text: this.$t("settings.store.storeSetup.updateSuccessMessage"),
      });
      resolve();
    },
  },
};
</script>
