<template>
  <RaiSetupForm
    :title="title"
    :type="type"
    :error-message="errorMessage"
    v-bind="$attrs"
    @submit="updateStore"
  >
    <VxFormGroup :title="$t('settings.store.storeSetup.basicInfoGroupTitle')">
      <v-row>
        <v-col cols="12" md="4">
          <VxTextField
            v-model="form.name"
            :label="$t('settings.store.storeSetup.nameField.label')"
            name="name"
            :placeholder="$t('settings.store.storeSetup.nameField.placeholder')"
            rules="required|max:255"
          />
        </v-col>
        <v-col cols="12" md="4">
          <VxTextField
            v-model="form.initials"
            :label="$t('settings.store.storeSetup.initialsField.label')"
            name="initials"
            :placeholder="
              $t('settings.store.storeSetup.initialsField.placeholder')
            "
            rules="required|max:3"
          />
        </v-col>
        <v-col cols="12" md="4">
          <VxAutocomplete
            v-model="form.timeZone"
            :label="$t('settings.store.storeSetup.timeZoneField.label')"
            name="timeZone"
            :placeholder="
              $t('settings.store.storeSetup.timeZoneField.placeholder')
            "
            rules="required"
            :items="timeZones"
            clearable
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <VxPhoneField
            v-model="form.landlineNumber"
            :label="$t('settings.store.storeSetup.phoneNumberField.label')"
            name="landlineNumber"
            :placeholder="
              $t('settings.store.storeSetup.phoneNumberField.placeholder')
            "
            :hint="phoneNumberHint"
            persistent-hint
            rules="required|na_phone"
          />
        </v-col>
        <v-col cols="12" md="6">
          <VxTextField
            v-model="form.callAndTextNumber"
            :label="
              $t('settings.store.storeSetup.callAndTextNumberField.label')
            "
            :placeholder="
              $t('settings.store.storeSetup.callAndTextNumberField.placeholder')
            "
            :hint="callAndTextNumberHint"
            :disabled="hasNoPhoneNumberDefined"
            persistent-hint
            readonly
          >
            <template #append>
              <VxTooltip type="info" bottom>
                {{
                  $t("settings.store.storeSetup.callAndTextNumberField.tooltip")
                }}
              </VxTooltip>
            </template>
          </VxTextField>
        </v-col>
      </v-row>
    </VxFormGroup>
    <VxFormGroup
      v-if="showStreetAddress"
      :title="$t('settings.store.storeSetup.streetAddressGroupTitle')"
    >
      <v-row>
        <v-col cols="12" md="6">
          <VxTextField
            v-model="form.address"
            :label="$t('settings.store.storeSetup.addressField.label')"
            :placeholder="
              $t('settings.store.storeSetup.addressField.placeholder')
            "
            readonly
          />
        </v-col>
        <v-col cols="12" md="6">
          <VxTextField
            v-model="form.city"
            :label="$t('settings.store.storeSetup.cityField.label')"
            :placeholder="$t('settings.store.storeSetup.cityField.placeholder')"
            readonly
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <VxTextField
            v-model="form.state"
            :label="$t('settings.store.storeSetup.stateField.label')"
            :placeholder="
              $t('settings.store.storeSetup.stateField.placeholder')
            "
            readonly
          />
        </v-col>
        <v-col cols="12" md="2">
          <VxTextField
            v-model="form.zip"
            :label="$t('settings.store.storeSetup.zipField.label')"
            :placeholder="$t('settings.store.storeSetup.zipField.placeholder')"
            readonly
          />
        </v-col>
        <v-col cols="12" md="6">
          <VxTextField
            v-model="form.country"
            :label="$t('settings.store.storeSetup.countryField.label')"
            :placeholder="
              $t('settings.store.storeSetup.countryField.placeholder')
            "
            readonly
          />
        </v-col>
      </v-row>
    </VxFormGroup>
    <template #action="{ next, submitting }">
      <v-skeleton-loader type="actions" :loading="$attrs.loading">
        <VxBtn v-if="showNewStore" text @click="$emit('new:store')">
          {{ $t("settings.store.storeSetup.newStoreBtnText") }}
        </VxBtn>
        <VxBtn text :loading="submitting" @click="next">
          {{ $t("settings.store.storeSetup.nextBtnText") }}
        </VxBtn>
      </v-skeleton-loader>
    </template>
  </RaiSetupForm>
</template>

<script>
import { useFormObject } from "@/mixins/useFormObject";
import {
  RaiSetupForm,
  SetupType,
  VxFormGroup,
  VxTextField,
  VxPhoneField,
  VxAutocomplete,
  VxTooltip,
  VxBtn,
} from "@/core-ui";

export default {
  name: "StoreSetup",
  components: {
    RaiSetupForm,
    VxFormGroup,
    VxTextField,
    VxPhoneField,
    VxAutocomplete,
    VxTooltip,
    VxBtn,
  },
  mixins: [
    useFormObject({
      prop: "store",
      default: {
        id: null,
        name: null,
        initials: null,
        timeZone: null,
        landlineNumber: null,
        callAndTextNumber: null,
        address: null,
        city: null,
        state: null,
        zip: null,
        country: null,
        warnWinmark: false,
      },
    }),
  ],
  inheritAttrs: false,
  props: {
    timeZones: {
      type: Array,
      required: true,
    },
    showNewStore: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    title() {
      return `Store ${this.store?.storeNumber}`;
    },
    type() {
      if (this.errorMessage) {
        return SetupType.WARNING;
      }

      return this.$attrs.type ?? SetupType.COMPLETE;
    },
    phoneNumberHint() {
      return this.hasNoPhoneNumberDefined
        ? this.$t("settings.store.storeSetup.phoneNumberField.hint")
        : "";
    },
    callAndTextNumberHint() {
      return !this.hasNoPhoneNumberDefined
        ? this.$t("settings.store.storeSetup.callAndTextNumberField.hint")
        : "";
    },
    hasNoPhoneNumberDefined() {
      return this.store?.landlineNumber === null;
    },
    showStreetAddress() {
      return this.store?.warnWinmark === false;
    },
    errorMessage() {
      return this.store?.warnWinmark === true
        ? this.$t("settings.store.storeSetup.warnWinmarkMessage")
        : "";
    },
  },
  methods: {
    updateStore(context) {
      this.$emit("update:store", context);
    },
  },
};
</script>
